import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/solid';
import FilterSelect from './FilterSelect';
import FilterDate from './FilterDate';
import Chip from '../../atoms/Chip';
import FilterSearch from './FilterSearch';

export default function FilterBar({
  filterData,
  addFilter,
  removeFilter,
  resetFilters,
  currentFilters,
  selected,
  setSelected,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  query,
  setQuery,
}) {
  if (!filterData || !filterData.categories) return null;

  const isAnyFilterSet =
    (currentFilters && currentFilters.length > 0) || query.length >= 3;

  return (
    <div className="h-full p-4 sm:p-6 md:p-8 bg-gray-50 rounded shadow flex flex-col">
      <div className="mb-2 px-0 sm:px-2 flex h-7">
        <h1 className="text-left text-gray-900 font-heading text-lg font-bold">
          Veranstaltungen filtern
        </h1>
        <Transition
          as={Fragment}
          show={isAnyFilterSet}
          enter="transition-opacity duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <span className="ml-auto group">
            <Chip
              onClick={resetFilters}
              label={
                <span className="text-primary-500 group-hover:text-primary-700">
                  Alle Filter zurücksetzen
                </span>
              }
              color="primary"
              icon={
                <XCircleIcon className="w-5 h-5 text-primary-500 ml-1 group-hover:text-primary-700 cursor-pointer" />
              }
            />
          </span>
        </Transition>
      </div>
      <div className="w-full flex flex-col sm:flex-row">
        <FilterSearch
          addFilter={addFilter}
          removeFilter={removeFilter}
          query={query}
          setQuery={setQuery}
        />
      </div>
      <div className="px-0 sm:px-2 flex h-7">
        <h2 className="text-left text-gray-900 font-heading text-sm font-bold">
          Weitere Filter
        </h2>
      </div>
      <div className="w-full flex flex-col sm:flex-row">
        <FilterSelect
          filterType="categories"
          filterData={filterData.categories.data}
          addFilter={addFilter}
          currentFilters={currentFilters}
          selected={selected}
          setSelected={setSelected}
        />
        <FilterSelect
          filterType="locations"
          filterData={filterData.locations.data}
          addFilter={addFilter}
          currentFilters={currentFilters}
          selected={selected}
          setSelected={setSelected}
        />
        <FilterSelect
          filterType="event_types"
          filterData={filterData.event_types.data}
          addFilter={addFilter}
          currentFilters={currentFilters}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <div className="w-full flex flex-col sm:flex-row">
        <FilterDate
          addFilter={addFilter}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
    </div>
  );
}

FilterBar.propTypes = {
  offers: PropTypes.array,
  query: PropTypes.string,
  setQuery: PropTypes.func.isRequired,
  setFilteredOffers: PropTypes.func.isRequired,
  filterData: PropTypes.object.isRequired,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  currentFilters: PropTypes.array.isRequired,
  selected: PropTypes.object,
  setSelected: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func.isRequired,
};
