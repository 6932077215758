import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Chip({ label, icon, color, onClick }) {
  const classes = classnames(
    'text-xs font-semibold py-1 px-3 rounded-xl cursor-pointer transition ease-in-out duration-300',
    {
      'pr-1 flex items-center': icon,
      'inline-block': !icon,
      'text-primary-500 bg-primary-50 hover:bg-primary-100':
        !color || color === 'primary',
      'text-gray-500 bg-gray-100 hover:bg-gray-200': color === 'gray',
    }
  );

  if (icon) {
    return (
      <div onClick={onClick} className={classes}>
        {label}
        {icon}
      </div>
    );
  }

  return (
    <div onClick={onClick} className={classes}>
      {label}
    </div>
  );
}

Chip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onClick: PropTypes.func,
};
