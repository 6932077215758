import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SearchIcon, XIcon } from '@heroicons/react/solid';

const FilterSearch = ({ addFilter, removeFilter, query, setQuery }) => {
  useEffect(() => {
    if (query.length >= 3) {
      addFilter('query', query);
    } else {
      removeFilter('query');
    }
  }, [query]);

  return (
    <div className="relative inline-block w-full py-2 sm:px-2 mb-4">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Veranstaltungen durchsuchen…"
        className="bg-white border-gray-300 relative group w-full py-2 pl-3 pr-10 text-left rounded-md shadow-sm border focus:border-primary-500 cursor-text transition duration-300 ease-out focus:outline-none"
      />
      <span className="absolute inset-y-0 right-0 flex items-center pr-4">
        {query.length >= 3 ? (
          <XIcon
            className="w-5 h-5 ml-2 opacity-100 text-gray-500 hover:text-primary-500 cursor-pointer"
            aria-hidden="true"
            onClick={() => {
              setQuery('');
              removeFilter('query');
            }}
          />
        ) : (
          <SearchIcon
            className="w-5 h-5 ml-2 opacity-50 text-gray-500"
            aria-hidden="true"
          />
        )}
      </span>
    </div>
  );
};

FilterSearch.propTypes = {
  query: PropTypes.string.isRequired,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
};

export default FilterSearch;
